import { LIST_CAST, LIST_CAST_FAIL, GET_CAST, DELETE_CAST, UPLOAD_CAST_IMG } from '../actions/types'

const initialState = {
  list: null,
  details: null,
  delete: null,
  upload_cast: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LIST_CAST:
      return {
        ...state,
        list: payload,
      }
    case LIST_CAST_FAIL:
      return {
        ...state,
        list: null,
      }
    case GET_CAST:
      return {
        ...state,
        details: payload,
      }
    case UPLOAD_CAST_IMG:
      return {
        ...state,
        upload_cast: payload,
      }
    case DELETE_CAST:
      return {
        ...state,
        delete: payload,
      }
    default:
      return state
  }
}
