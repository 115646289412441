import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Alert from 'src/layout/Alert'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { NavLink } from 'react-router-dom'
import { verifyPasswordOTP } from 'src/actions/auth'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import { createNewPassword } from 'src/actions/auth'

const CreateNewPassword = ({ verifed_usr }) => {
  const Id = localStorage.getItem('passId')
  const dispatch = useDispatch()
  // console.log(verifed_usr, 'user_verifed')

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  })
  const { password, passwordConfirm } = formData
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
  const [errMess, setErrMess] = useState(null)

  const checkPassword = (e) => {
    e.preventDefault()
    if (formData?.password === '' || formData?.password === undefined) {
      setErrMess('Password is required!')
    } else if (formData?.passwordConfirm === '' || formData?.passwordConfirm === undefined) {
      setErrMess('Confirm password is required!')
    } else if (formData?.password !== formData?.passwordConfirm) {
      setErrMess('Confirm password or Password does not match!')
    } else {
      setErrMess(null)
      dispatch(createNewPassword(formData, Id))
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <ToastContainer autoClose={2000} />
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Alert />
                  <CForm onSubmit={(e) => checkPassword(e)}>
                    <h2>Create New Password</h2>
                    {/* <p>If you have forgotten your password you can reset it here.</p> */}
                    <CInputGroup className="mb-4 mt-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        name="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => onChange(e)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        name="passwordConfirm"
                        placeholder="Confirm Password"
                        autoComplete="current-password"
                        value={passwordConfirm}
                        onChange={(e) => onChange(e)}
                      />
                    </CInputGroup>
                    {errMess !== null ? (
                      <p className="text-danger">
                        <small>{errMess}</small>
                      </p>
                    ) : (
                      ''
                    )}
                    <CRow>
                      <CCol xs={8}>
                        <CButton
                          color="primary"
                          type="submit"
                          // onClick={(e) => e.preventDefault()}
                          className="px-4"
                        >
                          Create New Password
                        </CButton>
                      </CCol>
                      {/* <CCol xs={12} className="text-right mt-3 text-center">
                        Already have an account?
                        <NavLink to="/login" color="link" className="px-2">
                          Login!
                        </NavLink>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5 intro-card" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Welcome to Dhakad Cinema</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  verifed_usr: state?.auth?.user_detail,
})
export default connect(mapStateToProps, { createNewPassword, verifyPasswordOTP })(CreateNewPassword)
