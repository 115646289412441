import { LIST_LANGUAGE, LIST_LANGUAGE_FAIL, GET_LANGUAGE, DELETE_LANGUAGE } from '../actions/types'

const initialState = {
  list: null,
  details: null,
  delete: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LIST_LANGUAGE:
      return {
        ...state,
        list: payload,
      }
    case LIST_LANGUAGE_FAIL:
      return {
        ...state,
        list: null,
      }
    case GET_LANGUAGE:
      return {
        ...state,
        details: payload,
      }
    case DELETE_LANGUAGE:
      return {
        ...state,
        delete: payload,
      }
    default:
      return state
  }
}
