import { SUPPORT_LIST } from '../actions/types'

const initialState = {
  list: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SUPPORT_LIST:
      return {
        ...state,
        list: payload,
      }
    default:
      return state
  }
}
