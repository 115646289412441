import { LIST_COUPON, LIST_COUPON_FAIL, GET_COUPON, DELETE_COUPON } from '../actions/types'

const initialState = {
  list: null,
  details: null,
  delete: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LIST_COUPON:
      return {
        ...state,
        list: payload,
      }
    case LIST_COUPON_FAIL:
      return {
        ...state,
        list: null,
      }
    case GET_COUPON:
      return {
        ...state,
        details: payload,
      }
    case DELETE_COUPON:
      return {
        ...state,
        delete: payload,
      }
    default:
      return state
  }
}
