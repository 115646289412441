/**
 * File: play.js ( playReducer function )
 * Author: Dravinanshu Mishra
 * Date: 2024-09-03
 * Version: 1.0.0
 *
 * Description:
 * This file contains the reducer function for managing various aspects of the play state.
 *
 * Purpose:
 * The play (Reducer) function updates the state of multiple play-related fields based on
 * dispatched actions. It centralizes the management of all "play" data within the Redux store.
 *
 * Error Handling:
 * The reducer returns the current state if the action type is not recognized.
 *
 * Modifications History:
 * - 2024-09-03: Initial version created by Dravinanshu Mishra.
 */

// 1. import play action types.
import { LIST_PLAY, LIST_PLAY_FAIL } from "../actions/types.js";

// 2. initialState declared.
const initialState = {
  list: null,
};

// 3. Reducer function.
const playReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_PLAY:
      return {
        ...state,
        list: payload,
      };
    case LIST_PLAY_FAIL:
      return {
        ...state,
        list: null,
      };
    default:
      return state;
  }
};

export default playReducer;
