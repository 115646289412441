import {
  LIST_MOVIE,
  LIST_MOVIE_FAIL,
  GET_MOVIE,
  UPLOAD_MOVIE,
  UPLOAD_TRAILER,
  UPLOAD_BANNERS,
  UPLOAD_SUBTITLES,
  GET_RECENT_MOVIE,
} from '../actions/types'

const initialState = {
  list: null,
  upload_movie: null,
  upload_trailer: null,
  upload_banner: null,
  upload_subtitle: null,
  details: null,
  recent_movies: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LIST_MOVIE:
      return {
        ...state,
        list: payload,
      }
    case LIST_MOVIE_FAIL:
      return {
        ...state,
        list: null,
      }
    case UPLOAD_MOVIE:
      return {
        ...state,
        upload_movie: payload,
      }
    case UPLOAD_TRAILER:
      return {
        ...state,
        upload_trailer: payload,
      }
    case UPLOAD_BANNERS:
      return {
        ...state,
        upload_banner: payload,
      }
    case UPLOAD_SUBTITLES:
      return {
        ...state,
        upload_subtitle: payload,
      }
    case GET_MOVIE:
      return {
        ...state,
        details: payload,
      }
    case GET_RECENT_MOVIE:
      return {
        ...state,
        recent_movies: payload,
      }

    default:
      return state
  }
}
