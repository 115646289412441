import axios from 'axios'
import { setAlert } from './alert'
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  FORGOT_PASSWORD,
  CREATE_NEW_PASSWORD,
  VERIFY_PASSWORD,
} from './types'
import { APIURL } from './config'
import setAuthToken from '../utils/setAuthToken'
import { toast } from 'react-toastify'
// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token)
  }
  try {
    const res = await axios.get(`${APIURL}/api/v1/users/`)
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    })
  } catch (error) {
    // toast.error(error.message)
    dispatch({
      type: AUTH_ERROR,
    })
  }
}

//Login User

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const userType = 'admin'
    const body = JSON.stringify({ email, password, userType })

    try {
      const res = await axios.post(`${APIURL}/api/v1/users/login`, body, config)

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      })
      if (res) {
        localStorage.setItem('token', res?.data?.token)
        localStorage.setItem('user_id', res?.data?.id)
        // console.log(res)
        const response = res.data ? res.data.message : res.message
        toast.success(response)
      }
      // dispatch(loadUser())
    } catch (err) {
      const error = err.response ? err.response.data.message : err.message
      if (error) {
        toast.error(error)
      }
      dispatch({
        type: LOGIN_FAIL,
      })
    }
  }

// Logout/Clear Profile
export const logout = () => (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    })
    toast.success('Admin LogOut Successfully')
    setTimeout(() => {
      localStorage.removeItem('token')
      localStorage.removeItem('user_id')
      window.location.href = '/'
    }, 2000)
  } catch (err) {
    const error = err.response ? err.response?.data?.message : err.message
    if (error) {
      toast.error(error)
    }
  }
}

// forgot password
export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const body = JSON.stringify({ email })
    try {
      const res = await axios.post(`${APIURL}/api/v1/users/forgotPassword`, body, config)
      if (res) {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: res?.data,
        })
        const response = res.data ? res.data.message : res.message
        toast.success(response)
      }
    } catch (err) {
      const error = err.response ? err.response.data.message : err.message
      if (error) {
        toast.error(error)
      }
    }
  }
export const verifyPasswordOTP = (value) => async (dispatch) => {
  try {
    var data = JSON.stringify({
      email: value.email,
      otp: Number(value.otp),
    })
    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await axios.patch(`${APIURL}/api/v1/users/verifyOTP`, data, config)
    const message = res?.data?.message
    if (res) {
      dispatch({
        type: VERIFY_PASSWORD,
        payload: res,
      })
      localStorage.setItem('passId', res?.data?.id)
      toast.success(message)
      setTimeout(() => {
        window.location.href = '/create-password'
      }, 2000)
    }
  } catch (err) {
    if (err) {
      toast.error('Please try again!')
    }
  }
}
//
export const resendOTP =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const body = JSON.stringify({ email })
    try {
      const res = await axios.post(`${APIURL}/api/v1/users/resendOTP`, body, config)
      if (res) {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: res?.data,
        })
        const response = res?.data ? res?.data?.message : res?.message
        toast.success(response)
      }
    } catch (err) {
      const error = err.response ? err.response.data.message : err.message
      if (error) {
        toast.error(error)
      }
    }
  }

export const createNewPassword = (value, id) => async (dispatch) => {
  // console.log(password, passwordConfirm, 'password, passwordConfirm')
  try {
    var data = JSON.stringify({
      password: value?.password,
      id: id,
    })
    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await axios.patch(`${APIURL}/api/v1/users/resetPassword/${id}`, data, config)
    const message = res?.data?.message
    if (res) {
      toast.success(message)
      setTimeout(() => {
        window.location.href = '/login'
      }, 1000)
    }
  } catch (err) {
    if (err) {
      toast.error('Token is invalid or has expired!')
    }
  }
}
