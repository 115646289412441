import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  FORGOT_PASSWORD,
  VERIFY_PASSWORD,
  CREATE_NEW_PASSWORD,
} from '../actions/types'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  user_detail: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      }
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: payload,
      }
    case VERIFY_PASSWORD:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user_detail: payload,
      }
    case CREATE_NEW_PASSWORD:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      }
    default:
      return state
  }
}
