import {
  LIST_USER,
  LIST_USER_FAIL,
  GET_USER,
  DELETE_USER,
  UPDATE_USER_PROFILE,
  GET_RECENT_USER,
} from '../actions/types'

const initialState = {
  list: null,
  details: null,
  delete: null,
  upload_profile: null,
  recent_users: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LIST_USER:
      return {
        ...state,
        list: payload,
      }
    case LIST_USER_FAIL:
      return {
        ...state,
        list: null,
      }
    case GET_USER:
      return {
        ...state,
        details: payload,
      }
    case GET_RECENT_USER:
      return {
        ...state,
        recent_users: payload,
      }
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        upload_profile: payload,
      }
    case DELETE_USER:
      return {
        ...state,
        delete: payload,
      }
    default:
      return state
  }
}
