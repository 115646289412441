import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import Alert from 'src/layout/Alert'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './style.css'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
// import { useHistory } from 'react-router-dom'
import { forgotPassword } from 'src/actions/auth'
import { NavLink } from 'react-router-dom'
import { verifyPasswordOTP, resendOTP } from 'src/actions/auth'
const ForgotPassword = ({ userDetail }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(forgotPassword({ email }))
  }

  console.log(userDetail?.status, 'userDetail')
  const userData = userDetail?.status === 'success' ? userDetail?.status : null

  //
  //
  const [otp, setOtp] = useState(new Array(4).fill(''))
  const otpNumbers = otp.join().replace(/,/g, '')

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))])
    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus()
    }
  }
  //
  const verifyHandle = (e) => {
    e.preventDefault()
    const verifyData = {
      email: email,
      otp: Number(otpNumbers),
    }
    dispatch(verifyPasswordOTP(verifyData))
  }
  const reSendOTPhandle = (e) => {
    e.preventDefault()
    const verifyData = {
      email: email,
    }
    dispatch(resendOTP(verifyData))
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <ToastContainer autoClose={2000} />
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {userData ? (
                    <CForm onSubmit={(e) => verifyHandle(e)}>
                      <h2>Verify OTP</h2>
                      <div
                        id="otp"
                        className="inputs d-flex flex-row justify-content-center my-2 forget-opt-form-fileds"
                      >
                        {otp.map((data, index) => {
                          return (
                            <input
                              className="text-center form-control rounded"
                              type="text"
                              name="otp"
                              maxLength="1"
                              value={data}
                              key={index}
                              onChange={(e) => handleChange(e.target, index)}
                              onFocus={(e) => e.target.select}
                            />
                          )
                        })}
                      </div>
                      <CRow>
                        <CCol xs={8}>
                          <CButton color="primary" type="submit" className="px-4">
                            Verify OTP
                          </CButton>
                        </CCol>
                        <CCol xs={12} className="otp-form-bottom-tex mt-2 d-flex">
                          <p>
                            Didn’t recieve OTP code?{' '}
                            <a
                              onClick={(e) => reSendOTPhandle(e)}
                              className="form-otp-resend cursor-pointer"
                            >
                              Resend
                            </a>
                          </p>
                        </CCol>
                      </CRow>
                    </CForm>
                  ) : (
                    <CForm onSubmit={(e) => onSubmit(e)}>
                      <h2>Forgot Your Password?</h2>
                      <p>If you have forgotten your password you can reset it here.</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="Email"
                          autoComplete="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs={6}>
                          <CButton color="primary" type="submit" className="px-4">
                            Reset Password
                          </CButton>
                        </CCol>
                        <CCol xs={12} className="text-right mt-3 text-center">
                          Already have an account?
                          <NavLink to="/login" color="link" className="px-2">
                            Login!
                          </NavLink>
                        </CCol>
                      </CRow>
                    </CForm>
                  )}
                  <Alert />
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5 intro-card" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Welcome to Dhakad Cinema</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userDetail: state?.auth?.user,
})

export default connect(mapStateToProps, { forgotPassword, verifyPasswordOTP })(ForgotPassword)
