import { LIST_ORDER, LIST_ORDER_FAIL, DELETE_ORDER } from '../actions/types'

const initialState = {
  list: null,
  delete: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LIST_ORDER:
      return {
        ...state,
        list: payload,
      }
    case LIST_ORDER_FAIL:
      return {
        ...state,
        list: payload,
      }
    case DELETE_ORDER:
      return {
        ...state,
        delete: payload,
      }
    default:
      return state
  }
}
